import { inject, Injectable } from '@angular/core';
import {
  NotificationStore,
  ProposalNotificationStore,
} from '../store/notification.store';

@Injectable({ providedIn: 'root' })
export class NotificationStoreService {
  store = inject(NotificationStore);
  proposalNotificationStore = inject(ProposalNotificationStore);
}
