import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { components } from '../api/models';
import { ApiResponse, SearchInput, SearchService } from './search.service';

export type INotificationFilter = Record<
  keyof components['schemas']['SearchNotificationDto'],
  any
>;

@Injectable({ providedIn: 'root' })
export class NotificationService
  implements
    SearchService<
      components['schemas']['NotificationView'],
      INotificationFilter
    >
{
  #apiService = inject(ApiService);
  search(payload: SearchInput<Partial<INotificationFilter>>): Observable<
    ApiResponse<{
      message?: string;
      link?: string;
      createdById?: string;
      createdByName?: string;
      createdOn?: string;
      intendedForRoleId?: string;
      readStatus?: 'READ' | 'UNREAD';
    }>
  > {
    return this.#apiService.callApi('/core/notifications/v1/search', 'post', {
      content: { 'application/json': this.buildSearchPayload(payload) },
    });
  }

  private buildSearchPayload(data: SearchInput<Partial<INotificationFilter>>) {
    return <
      SearchInput<Partial<components['schemas']['SearchNotificationDto']>>
    >{
      ...data,
      filter: {
        ...data.filter,
        readStatus: data.filter.readStatus?.status,
      },
    };
  }

  markRead(id: string) {
    return this.#apiService.callApi('/core/notifications/v1/markRead', 'put', {
      query: { id },
    });
  }
}
