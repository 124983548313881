import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BadgeModule } from 'primeng/badge';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { LayoutService } from './service/app.layout.service';
// import { AppMenuitemComponent } from './app.menuitem.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RoleService } from '../../auth/store/role.service';
import { HasPermissionDirective } from '../../shared/directives/has-permission.directive';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppConfigModule } from './config/config.module';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    SidebarModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,
    RouterModule,
    AppConfigModule,
    AppMenuitemComponent,
    HasPermissionDirective,
  ],
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];

  constructedModel: any[] = [];
  roleService = inject(RoleService);

  constructor(public layoutService: LayoutService) {}

  ngOnInit() {
    this.model = [
      {
        label: 'Home',
        items: [
          {
            label: 'Dashboard',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/main/home/dashboard'],
            hasPermission: {
              onResource: 'dashboard',
              onActions: ['listAllDashboard'],
              otherwise: 'HIDE',
            },
          },
          {
            label: 'Annual Business Plan',
            icon: 'pi pi-address-book',
            routerLink: ['/main/home/annual-business-plan'],
            hasPermission: {
              onResource: 'abp',
              onActions: ['listAllABP'],
              otherwise: 'HIDE',
            },
          },
        ],
      },
      {
        label: 'Lead to Contract',
        items: [
          // { label: 'Leads', icon: 'pi pi-fw pi-id-card', routerLink: ['/main/lead-to-contract/leads'] },
          // { label: 'Proposals', icon: 'pi pi-fw pi-check-square', routerLink: ['/main/lead-to-contract/proposals'] },
          {
            label: 'Leads',
            icon: 'pi pi-fw pi-crown',
            routerLink: ['/main/lead-to-contract/leads'],
            hasPermission: {
              onResource: 'lead',
              onActions: ['listAllLeads', 'listMyLeads'],
              otherwise: 'HIDE',
            },
          },
          {
            label: 'Proposals',
            icon: 'pi pi-fw pi-file-edit',
            routerLink: ['/main/lead-to-contract/proposals'],
            hasPermission: {
              onResource: 'tender',
              onActions: ['listAllProposal'],
              otherwise: 'HIDE',
            },
          },
          {
            label: 'Win-Loss',
            icon: 'pi pi-fw pi-bookmark',
            routerLink: ['/main/lead-to-contract/win-loss-list'],
            hasPermission: {
              onResource: 'winloss',
              onActions: ['listAllWinLoss'],
              otherwise: 'HIDE',
            },
          },
          {
            label: 'EMD Tracker',
            icon: 'pi pi-fw pi-money-bill',
            routerLink: ['/main/lead-to-contract/emd-tracker-list'],
            hasPermission: {
              onResource: 'emd',
              onActions: ['listAllEMDs', 'listMyEMDs'],
              otherwise: 'HIDE',
            },
          },
        ],
      },
      {
        label: 'contract to Plan',
        items: [
          {
            label: 'Projects (HOTO)',
            icon: 'pi pi-fw pi-file',
            routerLink: ['/main/hoto'],
            badge: 'NEW',
            hasPermission: {
              onResource: 'hoto',
              onActions: ['listAllHoTo'],
              otherwise: 'HIDE',
            },
          },
        ],
      },
      {
        label: 'master',
        items: [
          {
            label: 'Clients',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/main/master/clients'],
            badge: 'NEW',
            hasPermission: {
              onResource: 'client',
              onActions: ['listAllClient'],
              otherwise: 'HIDE',
            },
          },
          // {
          //   label: 'Cluster State',
          //   icon: 'pi pi-th-large',
          //   routerLink: ['/main/master/clusters'],
          //   badge: 'NEW',
          // },
          {
            label: 'Users',
            icon: 'pi pi-user-plus',
            routerLink: ['/main/master/users'],
            badge: 'NEW',
            hasPermission: {
              onResource: 'user',
              onActions: ['listAllUser'],
              otherwise: 'HIDE',
            },
          },
          {
            label: 'Roles',
            icon: 'pi pi-lock',
            routerLink: ['/main/master/roles'],
            badge: 'NEW',
            hasPermission: {
              onResource: 'role',
              onActions: ['listAllRole'],
              otherwise: 'HIDE',
            },
          },
          {
            label: 'Competitors',
            icon: 'pi pi-id-card',
            routerLink: ['/main/master/competitors'],
            badge: 'NEW',
            // hasPermission: {
            //   onResource: null,
            //   onActions: [],
            //   otherwise: 'HIDE',
            // },
          },
        ],
      },
    ];

    this.constructedModel = this.model.map((x) => {
      let isVisible = false;
      x.items.forEach(
        (y: any) =>
          (isVisible =
            isVisible ||
            (y.hasPermission
              ? this.roleService.hasPermission(
                  y.hasPermission?.onResource,
                  y.hasPermission?.onActions
                )
              : true))
      );

      return { ...x, visible: isVisible };
    });

    // NOTE: TEMPORARY FIX:: This cannot go on.
    if (this.roleService.hasRole('PMO')) {
      const mod = this.constructedModel.find(
        (x) => x.label?.toLowerCase() === 'master'
      );
      mod.visible = false;
    }
  }
}
