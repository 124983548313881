<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="/main/home/dashboard">
    <img src="assets/images/logo.png" alt="logo">
  </a>

  <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
    <i class="pi pi-bars"></i>
  </button>

  <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
    (click)="layoutService.showProfileSidebar()">
    <i class="pi pi-ellipsis-v"></i>
  </button>

  <div #topbarmenu class="layout-topbar-menu"
    [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
    <i class="pi pi-bell mt-2 mr-4 p-text-secondary notification-bell" pBadge style="font-size: 1.8rem"
      (click)="op.toggle($event)" [value]="unreadNotifications()" [badgeDisabled]="unreadNotifications() === 0"></i>

    <button class="p-link layout-topbar-button ProfileButton">
      <i class="pi pi-user"></i>
      <font>{{userName}} </font>
    </button>
    <button class="p-link layout-topbar-button" (click)="logout()">
      <i class="pi pi-sign-out"></i>
      <span>Logout</span>
    </button>
  </div>
</div>

<p-overlayPanel #op appendTo="body">
  <div class="flex flex-column gap-3 w-25rem">
    <div>
      <h2 class="text-lg ">Notifications</h2>
    </div>

    <!-- <div class="notification-toggle-btn">
      <p-selectButton [options]="notificationFilters" [(ngModel)]="notificationFilterInput" optionLabel="label"
        dataKey="status" (onChange)="filter($event)" />
    </div> -->
    <div class="mt-2">
      <div>
        @if (notifications().length === 0) {
        <p class="p-4">No notifications found.</p>
        }
        @for (group of keys(groupedNotifications()); track $index) {
        <p class="font-bold">{{group}}</p>
        @for (notification of (groupedNotifications()[group] || []); track $index) {
        <div class="px-2 py-1 notification_item">
          <a class="block flex align-items-center"
            [ngClass]="{'unread_notification': true, 'read_notification': notification.readStatus === 'READ'}"
            (click)="markRead(op, $event,  notification)">
            <p [innerHTML]="notification.message"></p>
          </a>
          <p class="mt-2 notification_duration">{{notification.duration}}</p>
        </div>
        }
        }
      </div>
    </div>
    @if (canBeLoadedMore()) {
    <div class="mt-2 action-btn-tertiary">
      <button pButton label="Load more Notifications" class="w-full" (click)="loadMore()"
        [loading]="pageLoading()"></button>
    </div>
    }
  </div>
</p-overlayPanel>