import { AuthService } from '@/auth/store/auth.service';
import { RoleService } from '@/auth/store/role.service';
import { NotificationStoreService } from '@/shared/service/notification-store.service';
import {
  NotificationFilters,
  NotificationVm,
} from '@/shared/store/notification.store';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { ScrollerModule } from 'primeng/scroller';
import {
  SelectButtonChangeEvent,
  SelectButtonModule,
} from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { AppConfigModule } from './config/config.module';
import { LayoutService } from './service/app.layout.service';
@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    SidebarModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,
    RouterModule,
    AppConfigModule,
    OverlayPanelModule,
    SelectButtonModule,
    ScrollerModule,
    ButtonModule,
  ],
  providers: [AuthService],
})
export class AppTopBarComponent {
  items!: MenuItem[];
  @ViewChild('menubutton') menuButton!: ElementRef;
  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;
  @ViewChild('topbarmenu') menu!: ElementRef;

  #authService = inject(AuthService);
  #roleService = inject(RoleService);
  #notificationStore = inject(NotificationStoreService)
    .proposalNotificationStore;
  #router = inject(Router);
  notifications = this.#notificationStore.paginatedData;
  userName = '';
  unreadNotifications = this.#notificationStore.totalRecords;
  groupedNotifications = this.#notificationStore.groupedProposals;
  notificationFilters = NotificationFilters;
  // notificationFilterInput = this.#notificationStore.filters.readStatus;
  notificationFilterInput = 'READ';
  canBeLoadedMore = this.#notificationStore.canBeLoadedMore;
  pageLoading = this.#notificationStore.paginationLoading;

  constructor(public layoutService: LayoutService) {
    this.userName = localStorage.getItem('userName') || '';
    // effect(
    //   () =>
    //     (this.notificationFilterInput =
    //       this.#notificationStore.filters().readStatus)
    // );
  }

  ngOnInit() {}
  logout() {
    this.#authService.logout();
  }

  filter($event: SelectButtonChangeEvent) {
    // this.#notificationStore.filter({
    //   filter: {
    //     readStatus: $event.value,
    //     intendedForRoleId: this.#roleService.getRole()?.roleId,
    //   },
    // });
  }

  isEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length === 0;
  }

  markRead(op: OverlayPanel, $event: MouseEvent, notification: NotificationVm) {
    // if (notification.readStatus === 'UNREAD') {
    //   this.#notificationStore.markReadOptimistic({
    //     id: notification.id || '',
    //     successCallback: () => {
    //       this.#notificationStore.refresh({});
    //     },
    //   });
    // }
    // op.toggle($event);
    this.#router
      .navigateByUrl(notification.link || '/')
      .then(() => window.location.reload());
    // .then((x) => window.location.reload());
  }

  loadMore() {
    this.#notificationStore.loadMore({});
  }
  keys(obj: { [key: string]: string[] }) {
    return Object.keys(obj);
  }
}
