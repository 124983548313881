import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { AppLayoutComponent } from './layout/main-layout/app.layout.component';
import { Action, Resource } from './auth/store/role.service';
import { LoginGuard } from './shared/guard/login-guard.guard';
import { AuthGuard } from './shared/guard/auth.guard';


export type RouteRoleData =
  | { bypass: boolean }
  | { resource: Resource; actions: Action[] };

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },

  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        canActivate: [LoginGuard],
        loadChildren: () =>
          import('./auth/auth.routes').then((x) => x.AuthRoutes)
      },
    ],
  },
  {
    path: 'main',
    component: AppLayoutComponent,
    canActivate: [MsalGuard, AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./main/main.routes').then((x) => x.routes),
      },
    ],
  },
];
