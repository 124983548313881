import { ProposalService } from '@/main/lead-to-contract/data-access/proposal.service';
import { ProposalNotificationVm } from '@/main/lead-to-contract/data-access/proposal.store';
import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import moment from 'moment';
import 'moment-timezone';
import { catchError, EMPTY, map, pipe, switchMap, tap } from 'rxjs';
import { components } from '../api/models';
import {
  INotificationFilter,
  NotificationService,
} from '../service/notifications.service';
import { withPagination } from '../store/withPagination';

export const NotificationFilters = [
  { label: 'All', status: undefined },
  { label: 'Unread', status: 'UNREAD' },
];

export type NotificationVm = components['schemas']['NotificationView'] & {
  duration: string;
};

export const NotificationStore = signalStore(
  withPagination<
    components['schemas']['NotificationView'],
    INotificationFilter
  >(NotificationService, 'main'),

  withComputed((store) => ({
    unreadNotificationsCount: computed(
      () =>
        store.paginatedData().filter((x) => x.readStatus === 'UNREAD').length
    ),
    groupedNotification: computed(() => {
      const today = moment().startOf('day');
      const groupedItems: any = {};

      store.paginatedData().forEach((item: any) => {
        const itemDate = moment(item.createdOn).startOf('day');
        const diffInDays = itemDate.diff(today, 'days');
        const duration = moment.tz(item.createdOn, 'UTC').fromNow();
        const enhancedItem: NotificationVm = {
          ...item,
          duration,
        };
        let category: string;
        if (diffInDays < -3) {
          category = 'Earlier';
        } else if (diffInDays >= -3 && diffInDays < -1) {
          category = 'Last 3 Days';
        } else if (diffInDays === -1) {
          category = 'Yesterday';
        } else if (diffInDays === 0) {
          category = 'Today';
        } else if (diffInDays === 1) {
          category = 'Tomorrow';
        } else {
          category = 'upcoming';
        }
        if (!groupedItems[category]) {
          groupedItems[category] = [];
        }
        groupedItems[category].push(enhancedItem);
      });
      return groupedItems;
    }),
  })),
  withMethods((store, service = inject(NotificationService)) => ({
    markRead: rxMethod<{
      id: string;
      successCallback?: (...args: any[]) => void;
    }>(
      pipe(
        switchMap((input) =>
          service.markRead(input.id).pipe(
            map((res) => {
              input.successCallback ? input.successCallback() : null;
            })
          )
        )
      )
    ),
    markReadOptimistic: rxMethod<{
      id: string;
      successCallback?: (...args: any[]) => void;
    }>(
      pipe(
        tap((_) => {
          const readNotifications = store.paginatedData().map((x) => {
            if (x.id === _.id) return { ...x, readStatus: 'READ' as any };
            return x;
          });
          patchState(store, (value) => ({
            ...value,
            paginatedData: readNotifications,
          }));
        }),
        switchMap((input) =>
          service.markRead(input.id).pipe(
            map((res) => {
              input.successCallback ? input.successCallback() : null;
            }),
            catchError((err: any) => {
              const readNotifications = store.paginatedData().map((x) => {
                if (x.id === input.id)
                  return { ...x, readStatus: 'UNREAD' as any };
                return x;
              });
              patchState(store, (value) => ({
                ...value,
                paginatedData: readNotifications,
              }));
              return EMPTY;
            })
          )
        )
      )
    ),
  }))
);

const TEMPLATE_NOTIFICATION =
  "Proposal <span class='font-bold'> {0} </span> has been marked as <span class='font-bold'> {1} </span>. Please take necessary action.";
const PROPOSAL_LINK =
  '/main/lead-to-contract/proposals/proposal-details/<proposalId>';

export const ProposalNotificationStore = signalStore(
  withPagination<
    components['schemas']['ProposalListRecordV2'],
    components['schemas']['ProposalSearchRecordV2']
  >(ProposalService, 'main'),
  withComputed((store) => ({
    groupedProposals: computed(() => {
      const today = moment().startOf('day');
      const groupedItems: any = {};

      store.paginatedData().forEach((item) => {
        const itemDate = moment(item.createdAt).startOf('day');
        const diffInDays = itemDate.diff(today, 'days');
        const duration = moment.tz(item.createdAt, 'UTC').fromNow();

        const status =
          item.status === 'SUBMITTED' ? 'Submitted for review' : '';
        const message = TEMPLATE_NOTIFICATION.replace(
          '{0}',
          item.tenderTittle || ''
        ).replace('{1}', status || '');
        const link = TEMPLATE_NOTIFICATION.replace(
          '{0}',
          item.tenderTittle || ''
        ).replace('{1}', item.status || '');

        const proposalNotification: ProposalNotificationVm = {
          message,
          link: PROPOSAL_LINK.replace('<proposalId>', item.id || ''),
          duration,
        };
        console.log('notif: ', proposalNotification);

        let category: string;
        if (diffInDays < -3) {
          category = 'Earlier';
        } else if (diffInDays >= -3 && diffInDays < -1) {
          category = 'Last 3 Days';
        } else if (diffInDays === -1) {
          category = 'Yesterday';
        } else if (diffInDays === 0) {
          category = 'Today';
        } else if (diffInDays === 1) {
          category = 'Tomorrow';
        } else {
          category = 'upcoming';
        }
        if (!groupedItems[category]) {
          groupedItems[category] = [];
        }
        groupedItems[category].push(proposalNotification);
      });
      return groupedItems;
    }),
  }))
);
